import React from "react"
import Fullpage from "../components/fullpage"
import Navbar from "../components/navbar"
import * as styles from "./about.module.css"
import { graphql } from "gatsby"

export default function About({ data }) {
  let html = "<div></div>";
  if (data.allMarkdownRemark.nodes.length >0 ){
    html = data.allMarkdownRemark.nodes[0].html;
  }
  
  return (
    <Fullpage>
      <Navbar />
      <div className={styles.main}>
        <div className={styles.text}>
          <div dangerouslySetInnerHTML={{ __html: `<div> ${html} </div>` }}></div>
        </div>
      </div>
    </Fullpage>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/info.md/"}}) {
      nodes {
        html
      }
    }
  }
`
